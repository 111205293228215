/* You can add global styles to this file, and also import other style files */

@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@400;500;600;700;800;900&display=swap");
@import "~@ng-select/ng-select/themes/default.theme.css";
/* global styles and  varibles */
:root {
  /*dark blue color*/
  --main-color: 4, 40, 92;

  /*yellow color*/
  --secondary-color: 211, 198, 72;

  /* transtion duration */
  --main-transtion-duration: 0.3s;
  /* border radious */
  --main-border-radious: 6px;
  /* section padding */
  --main-padding: 30px 0;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

*::selection {
  background-color: rgb(var(--main-color));
  color: #ffed76;
}

body {
  font-family: "Cairo", sans-serif;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

input:focus,
textarea:focus,
select:focus {
  outline: none !important;
  box-shadow: none !important;
}

@media (min-width: 1700px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1620px !important;
  }
}

/* custom components css styles */

/* home main slider customization */
.main-slider .owl-stage-outer.ng-star-inserted {
  border-radius: 30px;
}

.main-section-title {
  color: rgb(var(--main-color));
}

/* display background with black div */

.disable-background {
  display: none;
  position: fixed;
  left: 0%;
  top: 0%;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
}

.disable-background.show {
  display: block;
}

/* scroll styles */

::-webkit-scrollbar {
  width: 16px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #dbdbdb;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #acacac;
}

.ngx-pagination {
  margin-top: 20px;
  justify-content: end !important;
}

.ngx-pagination label {
  display: none;
}

.ngx-pagination .current {
  background: rgb(var(--main-color)) !important;
  color: rgb(var(--secondary-color)) !important;
}

.ngx-pagination a {
  color: rgb(var(--main-color)) !important;
}

/* close button */

.close-btn {
  position: absolute;
  right: 15px;
  top: 15px;
  background: #fff;
  width: 23px;
  text-align: center;
  border-radius: 50%;
  font-weight: 600;
  line-height: 1.3;
  font-size: 18px;
  height: 24px;
  cursor: pointer;
}

.ar {
  direction: rtl;
  text-align: right;
}

.en {
  direction: ltr;
  text-align: left;
}
